const INITIAL_STATE = {
  stocks: [],
  startDate: null,
  endDate: null,
  benchmark: null,
  rfRate: null,
  forecastedInput: null,
  portfolioBudget: null,
  stockDistribution: {},
  colorReport: null,
  recipientData: { name: null, email: null },
  userOpinion: [],
  cashBudget: null,
  userTrade: [],
  budgetAcc: 0,
  paMethodology: null,
};

const algoInputReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
  case 'STOCKS_INPUT':
    return { ...state, stocks: action.payload };
  case 'START_DATE_INPUT':
    return { ...state, startDate: action.payload };
  case 'END_DATE_INPUT':
    return { ...state, endDate: action.payload };
  case 'BENCHMARK_INPUT':
    return { ...state, benchmark: action.payload };
  case 'RF_RATE_INPUT':
    return { ...state, rfRate: action.payload };
  case 'FORECAST_INPUT':
    return { ...state, forecastedInput: action.payload };
  case 'PORTFOLIO_BUDGET_INPUT':
    return { ...state, portfolioBudget: action.payload };
  case 'STOCK_DISTRIBUTION_INPUT':
    return { ...state, stockDistribution: action.payload };
  case 'REPORT_COLOR_INPUT':
    return { ...state, colorReport: action.payload };
  case 'RECIPIENT_DATA_INPUT':
    return { ...state, recipientData: action.payload };
  case 'USER_OPINION_INPUT':
    return { ...state, userOpinion: [...state.userOpinion, action.payload] };
  case 'USER_TRADE_INPUT':
    return { ...state, userTrade: [...state.userTrade, action.payload] };
  case 'CASH_BUDGET_INPUT':
    return { ...state, cashBudget: action.payload };
  case 'METHODOLOGY_INPUT':
    return { ...state, paMethodology: action.payload };
  case 'BUDGET_ACC':
    return { ...state, budgetAcc: (state.budgetAcc += action.payload) };
  case 'RESET_OPINION_INPUT':
    return { ...state, userOpinion: [] };
  case 'RESET_TRADE_INPUT':
    return { ...state, userTrade: [] };
  case 'RESET_INPUTS':
    return INITIAL_STATE;
  default:
    return state;
  }
};
export default algoInputReducer;

import React, { useState, useEffect } from 'react';

const FancyLoadingPage = () => {
  const [color, setColor] = useState('#3498db'); // Initial color
  const [size, setSize] = useState(80); // Initial size

  // Function to update the color and size on page load
  useEffect(() => {
    // You can add more logic here to change colors and size dynamically
    setColor('#5f3dc4');
    setSize(120); 
  }, []);

  return (
    <div className="loading-container">
      <div
        className="loading-spinner"
        style={{
          borderTopColor: color,
          width: `${size}px`,
          height: `${size}px`,
        }}
      ></div>
    </div>
  );
};

export default FancyLoadingPage;
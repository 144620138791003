const INITIAL_STATE = {
  stocksUserValidation: false,
  datesUserValidation: false,
  benchmarkUserValidation: false,
  rfRateUserValidation: false,
  forecastUserValidation: false,
  portfolioBudgetValidation: false,
  stockDistributionValidation: false,
  reportColorValidation: false,
  recipientDataValidation: false,
  userOpinionValidation: false,
  cashBudgetValidation: false,
  userTradeValidation: false,
  methodologyValidation: false,
};

const validationReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
  case 'STOCKS_USER_INPUT':
    return { ...state, stocksUserValidation: action.payload };
  case 'DATES_USER_INPUT':
    return { ...state, datesUserValidation: action.payload };
  case 'BENCHMARK_USER_INPUT':
    return { ...state, benchmarkUserValidation: action.payload };
  case 'RFRATE_USER_INPUT':
    return { ...state, rfRateUserValidation: action.payload };
  case 'FORECAST_USER_INPUT':
    return { ...state, forecastUserValidation: action.payload };
  case 'PORTFOLIO_BUDGET_USER_INPUT':
    return { ...state, portfolioBudgetValidation: action.payload };
  case 'STOCK_DISTRIBUTION_USER_INPUT':
    return { ...state, stockDistributionValidation: action.payload };
  case 'REPORT_COLOR_USER_INPUT':
    return { ...state, reportColorValidation: action.payload };
  case 'RECIPIENT_DATA_USER_INPUT':
    return { ...state, recipientDataValidation: action.payload };
  case 'OPINION_USER_INPUT':
    return { ...state, userOpinionValidation: action.payload };
  case 'CASH_BUDGET_USER_INPUT':
    return { ...state, cashBudgetValidation: action.payload };
  case 'TRADE_USER_INPUT':
    return { ...state, userTradeValidation: action.payload };
  case 'METHODOLOGY_USER_INPUT':
    return { ...state, methodologyValidation: action.payload };
  case 'RESET_VALIDATIONS':
    return INITIAL_STATE;
  default:
    return state;
  }
};
export default validationReducer;

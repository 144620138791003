const INITIAL_STATE = { stocks: [], releases: [], latestViewerData: [] };

const ApiReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
  case 'GET_STOCKS':
    return { ...state, stocks: action.payload };
  case 'GET_RELEASES':
    return { ...state, releases: action.payload };
  case 'GET_STOCK_VIEWER_DATA':
    return { ...state, latestViewerData: action.payload };
  case 'RESET_CORE_DATA':
    return INITIAL_STATE;
  default:
    return state;
  }
};
export default ApiReducer;

const Copyright = () => {
  return (
    <footer className='copyright-container'>
      <p>
        Copyright ©
        <span className='copyright-text' variant='text' >
          Algoui
        </span>{' '}
        {new Date().getFullYear()}
        {'.'}
      </p>
    </footer>
  );
};

export default Copyright;

const INITIAL_STATE = { userSelectedTab: null, isUserBannerActive: true};


const selectedTabReducer = (state=INITIAL_STATE, action) => {
  switch (action.type) {
  case 'USER_SELECTED_TAB':
    return { ...state, userSelectedTab: action.payload };
  case 'IS_USER_BANNER_ACTIVE':
    return { ...state, isUserBannerActive: action.payload };
  default:
    return state;
  }
};

export default selectedTabReducer;
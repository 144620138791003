import {Suspense} from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { applyMiddleware, compose, createStore } from 'redux';
import reduxThunk from 'redux-thunk';

import FancyLoadingPage from './components/loading';
import App from './App';
import './i18n';
import Reducers from './reducers';

// lng:
let lng = 'en';
if (localStorage.getItem('i18nextLng')){
  lng = localStorage.getItem('i18nextLng').includes('-') ? localStorage.getItem('i18nextLng').split('-')[0] : localStorage.getItem('i18nextLng');
}

// small trick when switching tab:
let previousTitle = document.title;
window.addEventListener('blur', ()=>{
  previousTitle = document.title;
  document.title = lng === 'es' ? '¡No te vayas!' : 'Don\'t leave!';
});
window.addEventListener('focus', ()=>{
  document.title = previousTitle;
});

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(Reducers, composeEnhancers(applyMiddleware(reduxThunk)));

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <Provider store={store}>
    <Suspense fallback={<FancyLoadingPage/>}>
      <App />
    </Suspense>
  </Provider>,
);

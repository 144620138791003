import { useEffect } from 'react';
import { connect } from 'react-redux';
import { Navigate, Outlet } from 'react-router-dom';
import { bindActionCreators } from 'redux';

import { fetchUserName } from '../actions/index';

// we define this HOC to check if the user is authenticated before rendering the component

const PrivateRoute = ({ userName, fetchUserName }) => {
  useEffect(() => {
    fetchUserName('en');
  }, []);

  return userName !== 'Stranger' ? <Outlet /> : <Navigate to='/login' />;
};

const mapStateToProps = state => {
  return {
    userName: state.authData.userName,
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators({ fetchUserName }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(PrivateRoute);

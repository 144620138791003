const INITIAL_STATE = {
  analystDateRange: { startDate: null, endDate: null },
  priceDateRange: { startDate: null, endDate: null },
};

const dateRangeReducer = (state = INITIAL_STATE, action) => {
  let sortedDates;
  switch (action.type) {
  case 'ANALYST_DATES':
    sortedDates = action.payload.sort();
    if (sortedDates.length === 1) {
      return {
        ...state,
        analystDateRange: { startDate: sortedDates[0], endDate: sortedDates[0] },
      };
    } else {
      return {
        ...state,
        analystDateRange: {
          startDate: sortedDates[0],
          endDate: sortedDates[sortedDates.length - 1],
        },
      };
    }
  case 'PRICE_DATES':
    sortedDates = action.payload.sort();
    if (sortedDates.length === 1) {
      return { ...state, priceDateRange: { startDate: sortedDates[0], endDate: sortedDates[0] } };
    } else {
      return {
        ...state,
        priceDateRange: {
          startDate: sortedDates[0],
          endDate: sortedDates[sortedDates.length - 1],
        },
      };
    }
  default:
    return state;
  }
};
export default dateRangeReducer;

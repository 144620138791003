const INITIAL_STATE = { lastRunAlgorithm: null };

const algoRegistryReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
  case 'ALGO_REGISTRY':
    return { lastRunAlgorithm: action.payload };
  default:
    return state;
  }
};
export default algoRegistryReducer;

import StyledEngineProvider from '@mui/material/StyledEngineProvider';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import {useEffect} from 'react';
import './assets/styles/main.scss';
import PrivateRoute from './components/privateRoute';
import './styles.css';
import {lazy} from 'react';
import ReactGA from 'react-ga';

import Copyright from './components/copyright';

const LogIn = lazy(()=> import('./pages/authViews/logIn'));
const ResetPwd = lazy(()=> import('./pages/authViews/resetPassword'));
const PwdReestablishment = lazy(()=> import('./pages/authViews/restorePassword'));
const SignUp = lazy(()=> import('./pages/authViews/signUp'));
const ConfirmationResetedPwd = lazy(()=> import('./pages/authViews/confirmationForgotPassword'));
const ConfirmRestoredPwd = lazy(()=> import('./pages/authViews/confirmResetPassword'));

const AnalystInput = lazy(()=> import('./pages/algoInputViews/analysts'));
const BlackLitInput = lazy(()=> import('./pages/algoInputViews/bl'));
const FundamentalInput = lazy(()=> import('./pages/algoInputViews/fundamentals'));
const OptimizationInput = lazy(()=> import('./pages/algoInputViews/optimizations'));
const PaInput = lazy(()=> import('./pages/algoInputViews/pa'));
const PmInput = lazy(()=> import('./pages/algoInputViews/pmStocks'));
const ReportInput = lazy(()=> import('./pages/algoInputViews/report'));
const SignalToNoiseInput = lazy(()=> import('./pages/algoInputViews/stn'));
const VarInput = lazy(()=> import('./pages/algoInputViews/var'));

const Algorithms = lazy(()=> import('./pages/mainViews/algorithms'));
const HelpSection = lazy(()=> import('./pages/mainViews/helpSection'));
const Landing = lazy(()=> import('./pages/mainViews/landing'));
const Release = lazy(()=> import('./pages/mainViews/releases'));
const SavedSim = lazy(()=> import('./pages/mainViews/savedSimulations'));
const FinancialData = lazy(()=> import('./pages/mainViews/stockViewer'));

const CancelledPayment = lazy(()=> import('./pages/paymentViews/cancelledPayment'));
const SuccessPayment = lazy(()=> import('./pages/paymentViews/successPayment'));

const ConfirmedDeletedAccount = lazy(()=> import('./pages/userViews/confirmDelete'));
const MyProfile = lazy(()=> import('./pages/userViews/me'));

const NotFound = lazy(()=> import('./pages/otherViews/pageNotFound'));

const TRACKING_ID = process.env.REACT_APP_GA_ID;
ReactGA.initialize(TRACKING_ID);

function App() {

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  return (
    <>
      {/* Use to override MUI's styles. */}
      <StyledEngineProvider injectFirst>
        <BrowserRouter>
          <Routes>
            <Route path='/' exact element={<Landing />} />
            <Route path='/login' exact element={<LogIn />} />
            <Route path='/signUp' exact element={<SignUp />} />
            <Route path='/algorithms' exact element={<Algorithms />} />
            <Route path='/restorePassword' exact element={<PwdReestablishment />} />
            <Route path='/confirmationRestorePassword' exact element={<ConfirmRestoredPwd />} />

            <Route exact path='/' element={<PrivateRoute />}>
              <Route path='/resetPassword/:token' exact element={<ResetPwd />} />
              <Route path='/confirmationPasswordReset' exact element={<ConfirmationResetedPwd />} />
              <Route path='/YourProfile' exact element={<MyProfile />} />
              <Route path='/confirmedDeletedAccount' exact element={<ConfirmedDeletedAccount />} />
              <Route path='/algorithms/fundamental' exact element={<FundamentalInput />} />
              <Route path='/algorithms/analysts' exact element={<AnalystInput />} />
              <Route path='/algorithms/pm-stocks' exact element={<PmInput />} />
              <Route path='/algorithms/optimization' exact element={<OptimizationInput />} />
              <Route path='/algorithms/var' exact element={<VarInput />} />
              <Route path='/algorithms/report' exact element={<ReportInput />} />
              <Route path='/algorithms/stn' exact element={<SignalToNoiseInput />} />
              <Route path='/algorithms/bl' exact element={<BlackLitInput />} />
              <Route path='/algorithms/pa' exact element={<PaInput />} />
              <Route path='/savedSimulations' exact element={<SavedSim />} />
              <Route path='/successPayment' exact element={<SuccessPayment />} />
              <Route path='/cancelPayment' exact element={<CancelledPayment />} />
            </Route>

            <Route path='/financial-data' exact element={<FinancialData />} />
            <Route path='/help-section' exact element={<HelpSection />} />
            <Route path='/releases' exact element={<Release />} />
            <Route path='*' element={<NotFound />} />
          </Routes>
        </BrowserRouter>
        <Copyright />
      </StyledEngineProvider>
    </>
  );
}

export default App;

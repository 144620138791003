const INITIAL_STATE = { userName: null, userEmail: null, userTokens: null };

const userReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
  case 'GET_USERNAME':
    return {
      userName: action.payload.name,
      userEmail: action.payload.email,
      userTokens: action.payload.tokens,
    };
  default:
    return state;
  }
};

export default userReducer;

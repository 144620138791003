const INITIAL_STATE = {
  lastSimulationRunned: null,
  allSavedSimulations: [],
  numberSavedSimulations: null,
};

const algoRunReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
  case 'SIMULATION_OUTPUT':
    return { ...state, lastSimulationRunned: { data: { message: action.payload } } };
  case 'GET_SAVED_SIMULATIONS':
    return { ...state, allSavedSimulations: action.payload };
  case 'GET_NUMBER_SIMULATIONS':
    return { ...state, numberSavedSimulations: action.payload };
  default:
    return state;
  }
};
export default algoRunReducer;
